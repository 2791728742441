@import "./vars";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: $defaultFont;
  color: $black;
  word-break: break-word;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

a,
button {
  outline: none;
  &::-moz-focus-inner {
    border: 0;
  }
  &:focus,
  &:active {
    outline: none;
  }
}

input {
  border-radius: 5px;
  border: 1px solid $gray;
  padding: 12px 10px;
  &:focus {
    outline: none !important;
    border: 1px solid $defaultColor !important;
  }
}

img {
  display: block;
  width: 100%;
}

.red-text {
  color: $red;
}

.orange-text {
  color: $orange;
}

.green-text {
  color: $lightGreen;
}
