.gen-container {
  height: 100%;
  width: 100%;
  position: relative;
  padding: unset !important;
  margin: unset !important;

  .list-cont {
    //background-color: red;
    .list {
      position: absolute;
      //align-items: center;
      margin-top: 40%;

      li {
        //text-align: center;
        //background-color: #ececec;
        color: #5c7080;
      }

      .list-info {
        margin-left: 38px;
      }

      .number {
        background-color: #5c7080;
        color: #ffffff;
        border-radius: 50%;
        font-size: 10px;
        width: 30px;
        height: 30px;
        padding: 6px;
        margin-right: 5px;
      }

      .active {
        //background-color: #c1c0c0;
        color: #182026;
        font-weight: bold;

        .list-info {
          color: #5c7080;
        }

        .number {
          background-color: #182026;
        }
      }
    }
  }
  .general-form {
    background-color: #ffffff;
    margin-top: 1px;
  }

  .first {
    height: 100%;
  }
  .second {
    height: 100%;
  }

  .form-container {
    width: 100%;
    height: 100%;
    margin-right: unset !important;
    .col {
      width: 100%;
      height: 100%;
    }
  }

  .drop-zone {
    margin-top: 20px;
    height: 120px;
    cursor: pointer;
    .dzi {
      height: 83px;
      padding-top: 27px;
    }
  }

  .bp3-control.bp3-switch.bp3-large.switch {
    margin-top: 30px;
    .bp3-control-indicator {
      background-color: rgba(219, 55, 55, 0.5);
    }
    &-checked {
      margin-top: 30px;
      .bp3-control-indicator {
        background-color: #1fafc2;
      }
    }
  }
  .crf-first-footer-line {
    margin-left: -15px !important;
    margin-right: -15px !important;
    margin-top: unset !important;
    background-color: rgba(16, 22, 26, 0.15) !important;
    width: 520px;
  }

  .crf-footer-line {
    //margin-left: -15px  !important;
    margin-top: unset !important;
    background-color: rgba(16, 22, 26, 0.15) !important;
    width: 520px;
  }

  .logo-title {
    margin-top: 30px;
  }

  .first-button-cont {
    position: absolute;
    bottom: 10px;
    width: 100%;
    .first-button {
      //position: absolute;
      background-color: #1fafc2 !important;
      color: #ffffff !important;
      border-radius: 3px !important;
      width: 144px;
      height: 30px;
      background-image: none !important;
      &:hover {
        background-color: #00a0ae !important;
      }
    }
  }

  .second-buttons {
    position: absolute;
    bottom: 10px;
    width: 100%;
    .second-button {
      background-color: #1fafc2 !important;
      color: #ffffff !important;
      border-radius: 3px !important;
      width: 144px;
      height: 30px;
      background-image: none !important;
      &:hover {
        background-color: #00a0ae !important;
      }
    }
    .second-back-button {
      background-color: #f5f8fa !important;
      color: #182026 !important;
      border-radius: 3px !important;
      width: 144px;
      height: 30px;
      background-image: none !important;
      &:hover {
        background-color: rgba(16, 22, 26, 0.10000000149011612) !important;
      }
    }
  }

  .third-buttons {
    position: absolute;
    bottom: 10px;
    width: 100%;
    .third-button {
      background-color: #1fafc2 !important;
      color: #ffffff !important;
      border-radius: 3px !important;
      width: 144px;
      height: 30px;
      background-image: none !important;
      &:hover {
        background-color: #00a0ae !important;
      }
    }
    .third-back-button {
      background-color: #f5f8fa !important;
      color: #182026 !important;
      border-radius: 3px !important;
      width: 144px;
      height: 30px;
      background-image: none !important;
      &:hover {
        background-color: rgba(16, 22, 26, 0.10000000149011612) !important;
      }
    }
  }

  .fourth-buttons {
    margin-top: 59%;
    .fourth-button {
      background-color: #1fafc2 !important;
      color: #ffffff !important;
      border-radius: 3px !important;
      width: 144px;
      height: 30px;
      background-image: none !important;
      &:hover {
        background-color: #00a0ae !important;
      }
    }
    .fourth-back-button {
      background-color: #f5f8fa !important;
      color: #182026 !important;
      border-radius: 3px !important;
      width: 144px;
      height: 30px;
      background-image: none !important;
      &:hover {
        background-color: rgba(16, 22, 26, 0.10000000149011612) !important;
      }
    }
  }
}
