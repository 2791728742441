

.container{
    padding-top: 25px;
    margin-top:1px;
    background-color: #FFFFFF;

    .addSubmit {
        //position: absolute;
        background-color: #1FAFC2 !important;
        color: #FFFFFF !important;
        border-radius: 3px !important;
        width: 144px;
        height: 30px;
        background-image: none !important;
        right: 0;
        left: auto;
        margin-left: 81%;
        margin-bottom: 20px;
        &:hover{
            background-color: #00A0AE !important;
        }
    

    }

    .close-button {
        position: absolute;
        background-color: #FFFFFF !important;
        color: #182026 !important;
        border-radius: 3px !important;
        width: 144px;
        height: 30px;
        background-image: none !important;
        // right: 0;
        // left: auto;
        margin-left: 58%;
        // margin-bottom: 20px;
        &:hover{
            background-color: rgba(16, 22, 26, 0.2206748127937317) !important;
        }
    }
    
    .menu-titles {
        padding: 5px 0px;
    }

    .middle-line{
        margin-top: 10px !important;
        background-color: rgba(16, 22, 26, 0.15) !important;
        width: 100%;
    }

    .footer-line{
        margin-left: -15px  !important;
        background-color: rgba(16, 22, 26, 0.15) !important;
        width: 780px;
    }
}

