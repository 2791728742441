
    .size_selector {
        max-width: 60px;
        height: 25px;
        max-width: 60px !important;
        font-size: 14px !important;
        border-radius: 100%;
        background-color: rgba(92, 112, 128, 0.5);
        border: unset !important;
    }
