.sidebar_main {
  top: 0;
  position: fixed;
  height: auto;
  min-height: 100vh;
  background: #fff;
  z-index: 1070;
  will-change: transform;
  transition: -webkit-transform 0.2s ease-in-out;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  box-shadow: 0 0.125rem 9.375rem rgba(90, 97, 105, 0.1),
    0 0.25rem 0.5rem rgba(90, 97, 105, 0.12),
    0 0.9375rem 1.375rem rgba(90, 97, 105, 0.1),
    0 0.4375rem 2.1875rem rgba(165, 182, 201, 0.1);
  background-color: #293742;
  margin-bottom: -21px;
}

.min {
  max-width: 85px;
}

.sidebar_main-min {
  max-width: 85px;
}

.sidebar_mini .sidebar_main {
  width: 60px;
  background: #fff;
  margin-left: 0;
  -webkit-transform: none;
  transform: none;
  left: 0;
}

aside {
  width: 17%;
  top: 0;
}

.hidden-text-icon {
  margin-top: 8%;
  color: #bfccd6;
}

.visible-text-icon {
  margin-top: 15%;
  color: #bfccd6;
}
