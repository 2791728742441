$blue: #1976d2;;
$green: #226b1a;
$defaultColor: #226b1a;
$lightGreen: #598e56;
$lightBlue: #4b7ece;
$doubleLightGreen: #d6eeda;
$doubleLightBlue: #c0d9ff;
$white: #ffffff;
$black: #4f5052;
$formGray: #aeaeae;
$gray: #c1c0c0;
$lightGray: #f7f7f7;
$labelGray: #ececec;
$red: #f95b5b;
$orange: #f1cb52;
$transparentBlack: rgba(0,0,0,0.75);
$optionPanelWidth: 240px;
$border: #eaedf3;
$defaultColor: $blue;
$defaultLightColor: $lightBlue;
$defaultDoubleLightColor: $doubleLightBlue;
$defaultFont: 'Roboto', sans-serif;
$box-shadow: 2px 2px 8px 1px #ececec;
@import './mixins';

%box-shadow{
  @include box-shadow($box-shadow);
}
