.pagination{
    position:absolute;
    right: 30px;
    .page-item{

        .page-link{
            border: unset !important;
           
        }
        &.active{
            .page-link{
                color: #182026 !important;
                border: unset !important;
                background-color: #E1E8ED !important;
                border-radius: 100%;
            }
        }
    }
}
