.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  //padding: 1rem;
  margin-left: 114px;
  margin-right: 15px !important;

  .sidebarZone {
    width: 300px;
  }

  .sidebarZoneMin {
    width: 114px;
  }

  .logo {
    margin-left: 50px;
    margin-top: 3px;
  }

  hr.v {
    width: 1px;
    height: 20px;
    margin-top: unset !important;
    margin-bottom: unset !important;
  }

  .link-group {
    position: absolute;
    right: 0;
  }

  .menu {
    padding: 0px 25px;
  }

  .logo {
    min-width: 170px;
  }
}
