
.listAddButton {
    //position: absolute;
    background-color: #1FAFC2 !important;
    color: #FFFFFF !important;
    border-radius: 3px !important;
    width: 144px;
    height: 30px;
    background-image: none !important;
    right: 0;
    left: auto;
    margin-left: 69%;
    margin-bottom: 20px;
    &:hover{
        background-color: #00A0AE !important;
    }
}