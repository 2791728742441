@import "./partials/general";
@import "./utilities.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "~@blueprintjs/core/src/blueprint.scss";
@import "~@blueprintjs/table/src/table.scss";
@import "../icons/src/blueprint-icons.scss";
@import "~@blueprintjs/datetime/src/blueprint-datetime.scss";

.shadow {
  @extend %box-shadow;
}

p {
  &.centered {
    text-align: center;
  }
}

#root {
  & > .loading-icon-wrapper {
    height: 100vh;
    background-color: &$lightGray;
  }
}

.breadcrumb-item.active {
  a {
    color: #6c757d !important;
  }
}

.drop-zone {
  padding: 1rem;
  text-align: center;
  border: 1px dashed $formGray;
  color: $formGray;
}

.btn-xxs,
.component-btn-group .component-settings-button {
  padding: 2px 2px;
  font-size: 13px;
  line-height: 1.3em;
  border-radius: 0;
  width: 24px;
  height: 24px;
}

.formio-component-columns {
  padding-top: 25px;
  padding-bottom: 15px;
}

.formio-component-columns_custom {
  padding-top: 25px;
  padding-bottom: 15px;
}

.formio-component-container_custom {
  padding-top: 25px;
  padding-bottom: 15px;
}

.formio-component-container {
  padding-top: 25px;
  padding-bottom: 15px;
}

.formio-component-container_custom {
  padding-top: 25px;
  padding-bottom: 15px;
}

.formio-component-table {
  padding-top: 25px;
  padding-bottom: 15px;
}

.formio-component-table_custom {
  padding-top: 25px;
  padding-bottom: 15px;
}

.formio-component-panel_custom {
  padding-top: 25px;
  padding-bottom: 15px;
}

.clickable:hover {
  cursor: pointer;
}

.primary-button {
  background-color: #1fafc2 !important;
  color: #ffffff !important;
  border-radius: 3px !important;
  padding: 10px 15px;
  &:hover {
    background-color: #00a0ae !important;
  }
}

.min-vh-100 {
  min-height: 97vh !important;
}

.bp3-button.bp3-intent-primary {
  background-color: #1fafc2;
  color: #ffffff;
  &:hover {
    background-color: #00a0ae;
  }
}
.bp3-button.bp3-minimal.bp3-intent-primary {
  color: #00a0ae !important;
  background-color: transparent !important;
  &:hover {
    background-color: #8edde4ca !important;
  }
}

.white-lines {
  white-space: pre-line;
}

.page-break {
  page-break-after: always;
}

.mb17 {
  margin-bottom: 17px;
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex-right-center {
  @extend .flex-center;
  justify-content: end;
}
