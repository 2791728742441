.table-custom {
  text-align: center;
  border-top: 0 !important;
  border-bottom: 0 !important;

  border: 0 !important;

  thead {
    tr {
      :first-child {
        //background-color: orangered;
        border-left: 0 !important;
      }

      :last-child {
        border-right: 0 !important;
      }

      th {
        border-top: 0 !important;
      }
    }
  }

  tbody {
    tr {
      :first-child {
        border-left: 0 !important;
        //background-color: chocolate;
      }
    }

    td {
      border-right: 0 !important;
      border-right: 0 !important;
      border-bottom: 0 !important;
    }
  }
}

.searchButton {
  background-color: #1fafc2 !important;
  color: #ffffff !important;
  border-radius: 3px !important;
  width: 144px;
  height: 30px;
  background-image: none !important;
  &:hover {
    background-color: #00a0ae !important;
  }
}

.activeState {
  background-color: rgba(133, 255, 184, 0.4);
  color: #0f9960;
}

.inactiveState {
  background-color: rgba(233, 112, 112, 0.4);
  color: #f55656;
}

.stateBorder {
  padding: 2px 10px 2px 10px;
  border-radius: 12px;
  margin: 0 auto;
  width: 50%;
}

.paginator {
  margin-left: 85%;
}
