.container {
  padding-top: unset !important;

  .first-cont {
    padding-top: 25px;
  }

  .second-cont {
    background-color: #f5f8fa !important;
    padding-top: 25px !important;
  }

  .drop-zone {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .dropdown {
    margin-bottom: 20px;
  }

  .addSub {
    //position: absolute;
    background-color: #1fafc2 !important;
    color: #ffffff !important;
    border-radius: 3px !important;
    width: 144px;
    height: 30px;
    background-image: none !important;
    right: 0;
    left: auto;
    margin-left: 60%;
    margin-bottom: 20px;
    &:hover {
      background-color: #00a0ae !important;
    }
  }

  .user-close-button {
    position: absolute;
    background-color: #ffffff !important;
    color: #182026 !important;
    border-radius: 3px !important;
    width: 144px;
    height: 30px;
    background-image: none !important;
    // right: 0;
    // left: auto;
    margin-left: 10%;
    // margin-bottom: 20px;
    &:hover {
      background-color: rgba(16, 22, 26, 0.2206748127937317) !important;
    }
  }

  .error-required-icon {
    color: red;
  }

  .menu-titles {
    padding: 5px 0px;
  }

  .user-middle-line {
    margin-top: 25px !important;
    background-color: rgba(16, 22, 26, 0.15) !important;
    width: 100%;
  }

  .user-footer-line {
    margin-left: -15px !important;
    margin-top: unset !important;
    background-color: rgba(16, 22, 26, 0.15) !important;
    width: 780px;
  }

  .spinner-loader-listing {
    padding: 80px;
  }

}

.bp3-menu {
  max-height: 400px;
  overflow: auto;
}
