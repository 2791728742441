.nav-item-color {
    color: #BFCCD6;
}

.nav-item-secondary-color {
    color: #4a5c6a;
}

.img-logo {
    width: 50px;
    height: 50px;
}

hr {
    margin-top: 35px !important;
    background-color: #485a68 !important;
    width: 150%;
}


