.dialog-big {
  width: 1000px !important;
}

.bp3-select-popover .bp3-menu {
  max-height: 300px;
  max-width: 400px;
  overflow: auto;
  padding-top: 0;
}

.bp3-popover-wrapper.w-100 > .bp3-popover-target {
  width: 100%;
}

.select-large {
  height: 40px !important;
}