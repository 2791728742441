.custom-nav {
  position: relative !important;
  top: 0 !important;
}

.imgProfile {
  width: 38px;
  height: 38px;
}

.nav-card-item {
  max-height: 100%;
  margin: 0;
  padding: 0;
  height: 100%;
}

.profile-position {
  color: rgba(0, 0, 0, 0.5);
  font-family: SFProDisplay;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  width: 100%;
  text-align: left;
}

.profile-card {
  width: 19%;
  background-color: purple;
}

.crd-card {
  background-color: purple;
}

.crd-name {
  color: #5c7080;
  font-family: SFProDisplay;
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
  text-align: center;
}

.icon-box {
  margin-left: auto;
}

.brand-logo-container {
  width: 70%;
  margin: 20px 30px 0 15%;
}

.brand-favicon-container {
  width: 19%;
  margin: 0 auto;
  margin-top: 10%;
}

.notification-popover {
  .bp3-popover {
    width: 350px !important;
  }
}

.notification-tabs {
  .bp3-tab {
    width: 33%;
    margin: 0 !important;
    text-align: center;
  }

  .bp3-tab-panel {
    margin-top: 0;
    max-height: 350px;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.btn-header {
  position: relative;
  top: -5px;
  display: flex !important;
  color: #182026 !important;
}

.navbar-nav {
  flex-direction: row !important;
}
