.form-monitorization-container {
  float: right;
}

.form-monitorization-container-textarea {
  float: right;
}

.form-fill-inputs-button {
  background-color: transparent;
  margin-right: 10px;
  border: none;
  padding: 5px;
}

.highlighted-field {
  background-color: wheat;
}

.warning-feedback {
  color: darkgoldenrod;
}

.success-feedback {
  color: rgb(11, 148, 11);
}

.formio-component {
  page-break-inside: avoid;
}