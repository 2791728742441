.new-button{
     background-color: #1FAFC2 !important;
     color: #FFFFFF !important;
     border-radius: 3px !important;
     width: 144px;
     height: 30px;
     background-image: none !important;
     &:hover{
         background-color: #00A0AE !important;
     }
}

.patient-search-button {
    background-color: #1FAFC2 !important;
    color: #FFFFFF !important;
    border-radius: 3px !important;
    background-image: none !important;
    &:hover{
        background-color: #00A0AE !important;
    }
    svg{
        color: #FFFFFF;
    }
}