.formio-component-columns {
  padding-top: 20px;
  padding-bottom: 15px;
}

.formio-component-container {
  padding-top: 20px;
  padding-bottom: 15px;
}

.formio-component-table {
  padding-top: 20px;
  padding-bottom: 15px;
}
