@import '../../assets/scss/partials/vars';

#login{
  // position:absolute;
  // top:0;
  // left:0;
  width:100%;
  min-height: 100vh;
  background-color:$lightGray;
  @include d-flex-center();
  flex-direction: column;

  .title{
    text-align: center;
    margin-top:15px;
    //margin-bottom:30px;
    img{
      width: 65%;
      margin:auto;
      max-width:168px;
    }
    span{
      display: block;
      margin: 25px 0;
      font-size: 1.2em;
    }
  }
  .form-wrapper{
    // position: absolute;
    // top:50%;
    // left:50%;
    // transform: translate(-50%,-50%);
    @extend %box-shadow;
    padding:30px 25px;
    width:90%;
    background-color:white;
    max-width: 370px;
    form{
      padding: 12px;
    }
    .tip{
      padding: 0 20px;
      font-size: 15px;
    }
    .form-group-login{
      width:100%;
      padding:0;
      margin: 10px 0;
      input{
        width:100%;
      }
      .errors{
       span.error{
         font-size:14px;
         color: $red;
         margin-top: 5px;
         display:inline-block;
       } 
      }
    }
    button{
      display: block;
      margin:10px auto;
      background-color: $defaultColor;
      border: none;
      width:100%;
      padding:12px 10px;
      text-align: center;
      color: $white;
      border-radius: 8px;
    }
    
    div.error{
      margin-top: 15px;
      text-align: center;
      color: $red;
    }
  }
  .state-switch{
    text-align: center;
    font-size: 14px;
    padding: 15px 10px;
    span{
      color: $defaultColor;
      &:hover{
        cursor: pointer;
      }
    }
  }
}